import React, { useState, useContext, useEffect } from "react";
import Layout from "../../../components/layout";
import { AuthContext } from "../../../context/GlobalContextProvider";
import { navigate } from "gatsby";
import SEO from "../../../components/seo";
import {
  Form,
  Upload,
  Input,
  Button,
  Space,
  DatePicker,
  InputNumber,
  Select,
  Switch,
  message,
} from "antd";
import {
  MinusCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Plant from "./components/plant";
import NewPost from "./components/newPost";
import api, { url } from "../../../api";
import moment from "moment";
import UploadForm from "./components/uploadForm";
import TimelineMonth from "./components/timeline";

export default function NovoPredio() {
  const [editData, setEditData] = useState();
  const [posts, setPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState();
  const [postId, setPostId] = useState(editData?.post?.id);
  const [loadingData, setLoadingData] = useState(false);
  const [progress, setProgress] = useState([]);
  const [plants, setPlants] = useState([]);
  const [plantUrls, setPlantUrls] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [characteristics, setCharacteristics] = useState([]);

  const getData = async (id) => {
    setLoadingData(true);
    const { data } = await api.get(`/predios/${id}`);
    setEditData(data);
    setLoadingData(false);
    setCharacteristics(
      data.caracteristicas?.map((g, index) => ({
        fieldKey: index,
        isListField: true,
        key: index,
        name: index,
      })) ?? []
    );
    setProgress(
      data.progresso?.map((g, index) => ({
        fieldKey: index,
        isListField: true,
        key: index,
        name: index,
      })) ?? []
    );
    setPlants(
      data.planta?.map((g, index) => ({
        fieldKey: index,
        isListField: true,
        key: index,
        name: index,
      })) ?? []
    );
    setPlantUrls(
      data.planta?.map((p, index) => ({
        url: url && `${url}${p.planta?.url}`,
        index,
        id: p.id,
        planta: p.planta,
      }))
    );
    setTimelineUrls(
      data.timeline?.map((p, index) => {
        return p.fotos.map((f) => ({
          url: url && `${url}${f.url}`,
          index,
          id: f.id,
          foto: f,
        }));
      })
    );
    setTimeline(
      data.timeline?.map((g, index) => ({
        fieldKey: index,
        isListField: true,
        key: index,
        name: index,
      })) ?? []
    );
  };

  const searchPosts = async (value) => {
    setLoadingPosts(true);
    const postData = await api.get(`/posts?titulo_contains=${value}&_limit=10`);
    setPosts(postData.data);
    setLoadingPosts(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    const id =
      typeof window !== "undefined"
        ? new URLSearchParams(location.search).get("id")
        : null;
    id && getData(id);
    searchPosts("");
  }, []);

  const context = useContext(AuthContext);
  const [adress, setAdress] = useState(editData?.endereco);
  const [description, setDescription] = useState("");
  const [checked, setChecked] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [timelineUrls, setTimelineUrls] = useState([]);

  const handleSubmit = async (values, err) => {
    console.log(err);
    setLoadingSave(true);
    let post = postId;
    if (postId == 0) {
      var postData = new FormData();
      postData.append("files.capa", values.imagemPost[0].originFileObj);
      postData.append(
        "data",
        JSON.stringify({
          titulo: values.postTitulo,
          conteudo: description,
        })
      );
      await api({
        method: "POST",
        url: "/posts",
        headers: {
          Authorization: `Bearer ${context?.token}`,
        },
        data: postData,
      }).then((value) => {
        if (value) {
          post = value.data.id;
        }
      });
    }

    var data = new FormData();
    values.imagemPrincipal &&
      data.append(
        `files.imagemPrincipal`,
        values.imagemPrincipal[0]?.originFileObj,
        values.imagemPrincipal[0]?.name
      );
    values.capa &&
      data.append(
        `files.capa`,
        values.capa[0]?.originFileObj,
        values.capa[0]?.name
      );
    values.logo &&
      data.append(
        `files.logo`,
        values.logo[0]?.originFileObj,
        values.logo[0]?.name
      );
    values.cartao &&
      data.append(
        `files.cartao`,
        values.cartao[0]?.originFileObj,
        values.cartao[0]?.name
      );

    let galeria = [];
    values.galeria?.map((g) =>
      typeof g?.originFileObj === typeof {}
        ? data.append("files.galeria", g?.originFileObj, g.name)
        : galeria.push(g.uid)
    );

    let timeline = [];
    values.timeline?.map((t, index) => {
      let fotos = [];
      t.fotos?.map((g) =>
        typeof g?.originFileObj === typeof {}
          ? data.append(
              `files.timeline[${index}].fotos`,
              g?.originFileObj,
              g.name
            )
          : fotos.push(g.uid)
      );
      timeline.push({ fotos, mes: t.mes });
    });

    values.plantas?.map((p, index) => {
      p.planta &&
        data.append(
          `files.planta[${index}].planta`,
          p.planta[0]?.originFileObj,
          p.planta[0]?.name
        );
    });

    data.append(
      "data",
      JSON.stringify({
        nomePredio: values.nomePredio,
        titulo: values.titulo,
        subtitulo: values.subtitulo,
        galeria,
        timeline,
        descricao: values.descricao,
        planta: values.plantas?.map((p, index) => ({
          planta:
            (p.planta === undefined || p.planta === null) &&
            plantUrls[index]?.planta,
          titulo: p.titulo,
          descricao: p.descricao,
        })),
        caracteristicas: values.caracteristicas,
        progresso: values.progresso,
        endereco: adress,
        enderecoCheio: {
          cidade: values.cidade,
          estado: values.estadoEndereco,
          pais: values.pais,
          cep: values.cep,
          logradouro: values.logradouro,
          numero: values.numero,
          bairro: values.bairro,
          complemento: values.complemento,
        },
        classe: values.classe,
        estado: values.estado,
        fimObra: moment(values.fimObra).add(1, "day"),
        comecoObra: moment(values.comecoObra).add(1, "day"),
        apareceNoInicio: checked,
        post: post,
      })
    );
    try {
      await api({
        method: editData ? "PUT" : "POST",
        url: editData ? `predios/${editData.id}` : "/predios",
        headers: {
          Authorization: `Bearer ${context?.token}`,
        },
        data,
      }).then((value) => {
        message.success("Prédio salvo com sucesso");
        navigate(`/predio?id=${value.data.id}`);
      });
    } catch (error) {
      message.error(error.response?.data?.message);
      setLoadingSave(false);
    }
    setLoadingSave(false);
  };

  const uploadButton = (
    <div>
      {<UploadOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Layout image="" loading={loadingData}>
      <SEO title="Novo Prédio" />
      <Form scrollToFirstError onFinish={handleSubmit} layout="vertical">
        <div
          className="grid grid-cols-1 lg:grid-cols-3"
          style={{ width: "100%" }}
        >
          <UploadForm
            imgUrl={editData?.capa?.url && `${url}${editData.capa.url}`}
            label="Capa (o que aparece no carrossel)"
            field="capa"
            Form={Form}
          />
          <UploadForm
            imgUrl={editData?.cartao?.url && `${url}${editData.cartao.url}`}
            label="Cartão (o que aparece na página inicial)"
            field="cartao"
            Form={Form}
          />
          <UploadForm
            imgUrl={editData?.logo?.url && `${url}${editData.logo.url}`}
            label="Logo (o que aparece na barra superior)"
            field="logo"
            Form={Form}
          />
        </div>
        <Form.Item
          initialValue={editData?.nomePredio}
          name="nomePredio"
          rules={[{ required: true, message: "Dê um nome para o prédio" }]}
        >
          <Input placeholder="Nome do prédio" />
        </Form.Item>
        <Form.Item
          initialValue={editData?.titulo}
          name="titulo"
          rules={[{ required: true, message: "Adicione um título" }]}
        >
          <Input placeholder="Título" />
        </Form.Item>
        <Form.Item
          initialValue={editData?.subtitulo}
          name="subtitulo"
          rules={[{ required: true, message: "Adicione um subtítulo" }]}
        >
          <Input placeholder="Subtítulo" />
        </Form.Item>
        <div className="lg:flex">
          <Form.Item
            initialValue={editData?.classe}
            rules={[{ required: true, message: "Selecione uma classe" }]}
            className="lg:w-1/2 lg:mr-16"
            name="classe"
          >
            <Select placeholder="Classe">
              <Select.Option value="BivverADream">Bivver Dream</Select.Option>
              <Select.Option value="BivverBClub">Bivver Club</Select.Option>
              <Select.Option value="BivverCExclusivity">
                Bivver Exclusivity
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            initialValue={editData?.estado}
            rules={[{ required: true, message: "Selecione um estado" }]}
            className="lg:w-1/2"
            name="estado"
          >
            <Select placeholder="Estado da obra">
              <Select.Option value="EmConstrucao">Em construção</Select.Option>
              <Select.Option value="Entregue">Entrgue</Select.Option>
              <Select.Option value="PreLancamento">Pré-lançamento</Select.Option>
              <Select.Option value="Lancamento">Lançamento</Select.Option>
              <Select.Option value="Vendido">Vendido</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="lg:flex grid grid-cols-1">
          <UploadForm
            label="Imagem principal"
            imgUrl={
              editData?.imagemPrincipal?.url &&
              `${url}${editData.imagemPrincipal.url}`
            }
            field="imagemPrincipal"
            Form={Form}
            rules={[{ required: true, message: "Selecione uma imagem" }]}
          />
          <Form.Item
            className="lg:ml-24"
            initialValue={editData?.descricao}
            name="descricao"
            style={{ width: "100%" }}
            rules={[{ required: true, message: "Adicione uma descrição" }]}
            label="Descrição"
          >
            <Input.TextArea style={{ height: "15rem" }} />
          </Form.Item>
        </div>
        <h2 className="my-10 text-center">Características</h2>
        <Form.List initialValue={characteristics} name="caracteristicas">
          {(fields, { add, remove }) => {
            fields = characteristics;
            return (
              <div>
                {fields.map((field, index) => (
                  <Space
                    key={field?.key}
                    style={{ display: "flex", marginBottom: 8, width: "100%" }}
                    align="start"
                  >
                    <Form.Item
                      {...field}
                      initialValue={editData?.caracteristicas[index]?.titulo}
                      name={[field?.name, "titulo"]}
                      fieldKey={[field?.fieldKey, "titulo"]}
                      rules={[
                        { required: true, message: "Adicione um título" },
                      ]}
                    >
                      <Input placeholder="Título" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      initialValue={editData?.caracteristicas[index]?.descricao}
                      name={[field?.name, "descricao"]}
                      fieldKey={[field?.fieldKey, "descricao"]}
                      rules={[
                        { required: true, message: "Adicione uma descrição" },
                      ]}
                    >
                      <Input.TextArea autoSize placeholder="Descrição" />
                    </Form.Item>

                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field?.name);
                        setCharacteristics(
                          characteristics.filter(
                            (p) => p.name !== characteristics.length - 1
                          )
                        );
                      }}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                      setCharacteristics([
                        ...characteristics,
                        {
                          fieldKey:
                            characteristics.length > 0
                              ? characteristics[characteristics.length - 1]
                                  .fieldKey + 1
                              : 0,
                          isListField: true,
                          key:
                            characteristics.length > 0
                              ? characteristics[characteristics.length - 1]
                                  .key + 1
                              : 0,
                          name:
                            characteristics.length > 0
                              ? characteristics[characteristics.length - 1]
                                  .name + 1
                              : 0,
                        },
                      ]);
                    }}
                    block
                  >
                    <PlusOutlined /> Adicionar característica
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <h2 className="my-10 text-center">Plantas</h2>
        <Form.List name="plantas">
          {(fields, { add, remove }) => {
            fields = plants;
            return (
              <div>
                {fields.map((field, index) => (
                  <Plant
                    key={field?.key}
                    initialValue={{
                      titulo: editData?.planta[index]?.titulo,
                      descricao: editData?.planta[index]?.descricao,
                      imgUrl: `${plantUrls[index]?.url ?? ""}`,
                    }}
                    {...{
                      field,
                      remove,
                      Form,
                      plants,
                      setPlants,
                      plantUrls,
                      setPlantUrls,
                    }}
                  />
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      setPlants([
                        ...plants,
                        {
                          fieldKey:
                            plants.length > 0
                              ? plants[plants.length - 1].fieldKey + 1
                              : 0,
                          isListField: true,
                          key:
                            plants.length > 0
                              ? plants[plants.length - 1].key + 1
                              : 0,
                          name:
                            plants.length > 0
                              ? plants[plants.length - 1].name + 1
                              : 0,
                        },
                      ]);
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> Adicionar planta
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <h2 className="text-center mt-16">Acompanhamento da obra</h2>
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:ml-64 my-3">
          <Form.Item
            initialValue={editData?.comecoObra && moment(editData?.comecoObra)}
            name="comecoObra"
            label="Início da obra"
          >
            <DatePicker format="DD/MM/YYYY" className="mr-5" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.fimObra && moment(editData?.fimObra)}
            name="fimObra"
            label="Término da obra"
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </div>

        <Form.List initialValue={progress} name="progresso">
          {(fields, { add, remove }) => {
            fields = progress;
            return (
              <div>
                {fields.map((field, index) => (
                  <Space
                    key={field?.key}
                    style={{ display: "flex", marginBottom: 8, width: "100%" }}
                    align="start"
                  >
                    <Form.Item
                      {...field}
                      initialValue={editData?.progresso[index]?.descricao}
                      name={[field?.name, "descricao"]}
                      fieldKey={[field?.fieldKey, "descricao"]}
                      rules={[
                        { required: true, message: "Adicione uma descrição" },
                      ]}
                    >
                      <Input placeholder="Descrição" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      initialValue={editData?.progresso[index]?.porcentagem}
                      name={[field?.name, "porcentagem"]}
                      fieldKey={[field?.fieldKey, "porcentagem"]}
                      rules={[
                        { required: true, message: "Adicione uma porcentagem" },
                      ]}
                    >
                      <InputNumber placeholder="Porcentagem" />
                    </Form.Item>

                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field?.name);
                        setProgress(
                          progress.filter((p) => p.name !== progress.length - 1)
                        );
                      }}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                      setProgress([
                        ...progress,
                        {
                          fieldKey:
                            progress.length > 0
                              ? progress[progress.length - 1].fieldKey + 1
                              : 0,
                          isListField: true,
                          key:
                            progress.length > 0
                              ? progress[progress.length - 1].key + 1
                              : 0,
                          name:
                            progress.length > 0
                              ? progress[progress.length - 1].name + 1
                              : 0,
                        },
                      ]);
                    }}
                    block
                  >
                    <PlusOutlined /> Adicionar barra de progresso
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <h2 className="font-bold text-center mt-16 mb-10">Linha do tempo</h2>
        <Form.List initialValue={timeline} name="timeline">
          {(fields, { add, remove }) => {
            fields = timeline;
            return (
              <div>
                {fields.map((field, index) => (
                  <TimelineMonth
                    key={index}
                    initialValue={{
                      mes: editData?.timeline[index]?.mes,
                      fotos: timelineUrls[index],
                    }}
                    {...{ remove, field, timeline, setTimeline, Form }}
                  />
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                      setTimeline([
                        ...timeline,
                        {
                          fieldKey:
                            timeline.length > 0
                              ? timeline[timeline.length - 1].fieldKey + 1
                              : 0,
                          isListField: true,
                          key:
                            timeline.length > 0
                              ? timeline[timeline.length - 1].key + 1
                              : 0,
                          name:
                            timeline.length > 0
                              ? timeline[timeline.length - 1].name + 1
                              : 0,
                        },
                      ]);
                    }}
                    block
                  >
                    <PlusOutlined /> Adicionar mês
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <h2 className="font-bold text-center mt-16 mb-10">Localização</h2>
        <Form.Item
          initialValue={editData?.endereco}
          rules={[{ required: true, message: "Digite um endereço" }]}
          name="endereco"
        >
          <Input
            placeholder="Digite o endereço"
            className="mb-10"
            value={adress}
            onChange={(e) => setAdress(e.target.value)}
          />
        </Form.Item>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-12">
          <Form.Item
            initialValue={editData?.enderecoCheio?.logradouro}
            rules={[{ required: true, message: "Digite um logradouro" }]}
            name="logradouro"
            label="Logradouro"
          >
            <Input placeholder="Digite o logradouro" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.numero}
            rules={[{ required: true, message: "Digite um número" }]}
            name="numero"
            label="Número"
          >
            <Input placeholder="Digite o número" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.complemento}
            name="complemento"
            label="Complemento"
          >
            <Input placeholder="Digite o complemento" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.cep}
            name="cep"
            label="Cep"
          >
            <Input placeholder="Digite o cep" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.bairro}
            name="bairro"
            label="Bairro"
          >
            <Input placeholder="Digite o bairro" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.cidade}
            rules={[{ required: true, message: "Digite uma cidade" }]}
            name="cidade"
            label="Cidade"
          >
            <Input placeholder="Digite a cidade" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.estado}
            rules={[{ required: true, message: "Digite um estado" }]}
            name="estadoEndereco"
            label="Estado"
          >
            <Input placeholder="Digite o estado" className="mb-10" />
          </Form.Item>
          <Form.Item
            initialValue={editData?.enderecoCheio?.pais}
            rules={[{ required: true, message: "Digite um país" }]}
            name="pais"
            label="País"
          >
            <Input placeholder="Digite o país" className="mb-10" />
          </Form.Item>
        </div>
        {adress && (
          <iframe
            className="lg:w-1/2 w-full lg:mx-64 border-solid border-2 border-blue-500"
            title="SoftinLocationMap"
            height="360"
            frameBorder="0"
            src={`https://maps.google.com/maps?q=${encodeURI(
              adress
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen=""
          />
        )}
        <h2 className="text-center mt-16">Galeria de imagens</h2>
        <Form.Item
          initialValue={editData?.galeria?.map((g) => ({
            uid: g.id,
            name: g.name + g.ext,
            status: "done",
            url: g.url && `${url}${g.url}`,
          }))}
          name="galeria"
          getValueFromEvent={normFile}
          valuePropName="fileList"
        >
          <Upload listType="picture-card">{uploadButton}</Upload>
        </Form.Item>
        <Form.Item name="post" initialValue={editData?.post?.id}>
          <Select
            value={postId}
            filterOption={false}
            notFoundContent={loadingPosts && <LoadingOutlined />}
            showSearch
            onSearch={searchPosts}
            onChange={(value) => setPostId(value)}
          >
            <Select.Option value={0}>Criar novo post</Select.Option>
            {posts.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.titulo}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {postId === 0 && <NewPost {...{ Form, description, setDescription }} />}
        <Form.Item name="apareceNoInicio" className="mt-16">
          <Switch
            size="small"
            checked={checked}
            value={checked}
            style={{ backgroundColor: checked ? "orange" : "" }}
            onChange={() => (checked ? setChecked(false) : setChecked(true))}
            className="mr-3"
          />
          <span className="font-semibold mt-1">Aparece na tela inicial?</span>
        </Form.Item>
        <Form.Item>
          <Button
            disabled={loadingSave}
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
