import React, { useState } from "react";
import { Upload, Input } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import dynamic from "@loadable/component";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";

const MdEditor = dynamic(() => import("react-markdown-editor-lite"), {
  ssr: false,
});

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

export default function NewPost({ Form, description, setDescription }) {
  const [loadingImg, setLoadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingImg(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoadingImg(false);
      });
    }
  };
  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <UploadOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  if (Form === undefined) return <></>;
  else
    return (
      <>
        <h2 className="text-center mt-16">Post relacionado</h2>
        <Form.Item name="postTitulo">
          <Input className="my-5" placeholder="Título" />
        </Form.Item>
        <Form.Item
          name="imagemPost"
          label="Imagem principal"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            style={{ height: 300 }}
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <MdEditor
          className="mb-10"
          value={description}
          onChange={(e) => setDescription(e.text)}
          renderHTML={(text) => mdParser.render(text)}
          style={{ height: 500 }}
        />
      </>
    );
}
NewPost.propTypes = {
  Form: PropTypes.node,
  description: PropTypes.any,
  setDescription: PropTypes.func,
};
